import React from 'react';
import { Box, TextField, Select, MenuItem, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { ChromePicker } from 'react-color';
import PropTypes from 'prop-types';

const fonts = [
  'Arial', 'Helvetica', 'Times New Roman', 'Courier New', 'Verdana', 'Georgia', 'Pacifico, Lobster'
];

const FontControls = ({ fontData, onFontChange }) => {
  const { 'font-family': fontFamily, 'font-size': fontSize, 'font-color': fontColor, 'font-weight': fontWeight, 'font-style': fontStyle } = fontData;

  const handleFontFamilyChange = (e) => {
    onFontChange({ ...fontData, 'font-family': e.target.value });
  };

  const handleFontSizeChange = (e) => {
    onFontChange({ ...fontData, 'font-size': e.target.value });
  };

  const handleFontColorChange = (color) => {
    onFontChange({ ...fontData, 'font-color': color.hex });
  };

  const handleFontWeightChange = (e) => {
    onFontChange({ ...fontData, 'font-weight': e.target.checked ? 'bold' : 'normal' });
  };

  const handleFontStyleChange = (e) => {
    onFontChange({ ...fontData, 'font-style': e.target.checked ? 'italic' : 'normal' });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography gutterBottom>Font Family</Typography>
      <Select
        value={fontFamily || 'Arial'}
        onChange={handleFontFamilyChange}
      >
        {fonts.map((font) => (
          <MenuItem key={font} value={font}>
            {font}
          </MenuItem>
        ))}
      </Select>
      <TextField
        label="Font Size"
        type="text"
        value={fontSize || '16px'}
        onChange={handleFontSizeChange}
      />
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography gutterBottom>Font Color</Typography>
        <ChromePicker
          color={fontColor || '#000000'}
          onChangeComplete={handleFontColorChange}
        />
      </Box>
      <FormControlLabel
        control={<Checkbox checked={fontWeight === 'bold'} onChange={handleFontWeightChange} />}
        label="Bold"
      />
      <FormControlLabel
        control={<Checkbox checked={fontStyle === 'italic'} onChange={handleFontStyleChange} />}
        label="Italic"
      />
    </Box>
  );
};

FontControls.propTypes = {
  fontData: PropTypes.object.isRequired,
  onFontChange: PropTypes.func.isRequired,
};

export default FontControls;
