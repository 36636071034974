import React, {useState, useEffect} from 'react';
import { Button, Slider } from '@mui/material';
import './Control.css';
import { useIntl } from 'react-intl';
import SliderContainer from './SliderContainer';
import StepLine from './StepLine';

const Controls = ({
  offsetX,
  offsetY,
  scale,
  autoRedraw,
  selectedSize,
  handleOffsetXChange,
  handleOffsetYChange,
  handleScaleChange,
  handleAutoRedrawChange,
  handleSizeChange,
  handleOverlayImages
}) => {
  const intl = useIntl();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      
    <div className="step-container">
      <SliderContainer 
        id='scale'
        value={scale}
        onChange={handleScaleChange}
        defaultValue={0} // Початкове значення ползунка (необов'язково)
        min={-30} // Мінімальне значення
        max={30} // Максимальне значення
        step={1} // Крок зміни значення
        label={intl.formatMessage({ id: 'scale' })}
      />
      <SliderContainer 
        id="offsetX"
        value={offsetX}
        onChange={handleOffsetXChange}
        defaultValue={0} // Початкове значення ползунка (необов'язково)
        min={-1000} // Мінімальне значення
        max={1000} // Максимальне значення
        step={5} // Крок зміни значення
        label={intl.formatMessage({ id: 'fit_by_X' })}
      />
      <SliderContainer 
        id="offsetY"
        value={offsetY}
        onChange={handleOffsetYChange}
        defaultValue={0} // Початкове значення ползунка (необов'язково)
        min={-1000} // Мінімальне значення
        max={1000} // Максимальне значення
        step={5} // Крок зміни значення
        label={intl.formatMessage({ id: 'fit_by_Y' })}
      />
      
      {/*<div>
        <input
          type="checkbox"
          id="autoRedraw"
          checked={autoRedraw}
          onChange={handleAutoRedrawChange}
        />
        <label htmlFor="autoRedraw">Auto Redraw</label>
  </div>*/}
      {/*<div>
        <input
          type="radio"
          id="large"
          value="large"
          checked={selectedSize === 'large'}
          onChange={handleSizeChange}
        />
        <label htmlFor="large">Large (1875x1020)</label>
      </div>
      <div>
        <input
          type="radio"
          id="medium"
          value="medium"
          checked={selectedSize === 'medium'}
          onChange={handleSizeChange}
        />
        <label htmlFor="medium">Medium (600x600)</label>
      </div>
      <div>
        <input
          type="radio"
          id="small"
          value="small"
          checked={selectedSize === 'small'}
          onChange={handleSizeChange}
        />
        <label htmlFor="small">Small (200x200)</label>
</div>*/}
      
    </div>
    </>
  );
};

export default Controls;
