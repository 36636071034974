import React from 'react';
import './Preview.css'; 

const Preview = ({ overlayedImage, handleDownload }) => {

  

  return (
    <div>
      {overlayedImage && (
        <>
          <div>
          <img width='100%' style={{maxWidth: '600px'}} src={overlayedImage} alt="Overlayed Image" />
          
          
          
          </div>
        </>
      )}
    </div>
  );
};

export default Preview;
