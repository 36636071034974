const data = {
    bgimg: 'https://api.rotary.org.ua/uploads/brandcenter/certificatemaker/certificate_iyfr_1/iyfr.png',
    size: { width: 3508, height: 2480 },
    layer: [
      {
        imgurl: 'https://api.rotary.org.ua/uploads/brandcenter/certificatemaker/certificate_iyfr_1/iyfr_logo2.jpg',
        location: {
          offsetX: 1453,
          offsetY: 165,
          scale: '80%',
        },
      },
      {
        imgurl: 'https://api.rotary.org.ua/uploads/brandcenter/certificatemaker/certificate_iyfr_1/IYFR_logo.png',
        location: {
          offsetX: 852,
          offsetY: 1901,
          scale: '120%',
        },
      },
      {
        text0: 'International Yachting Fellowship of Rotarians',
        location: {
          offsetX: 1754,
          offsetXcenter: true,
          offsetY: 752,
          offsetYcenter: false,
          'font-family': 'Arial',
          'font-size': '130px',
          'font-color': 'darkblue',
          'font-weight': 'bold',
        },
      },
      {
        text0: `By authority of theITFR Executive Committee
        And upon recomendation by its Regional and Area Commodores, the`,
        location: {
          offsetX: 1754,
          offsetXcenter: false,
          offsetY: 1016,
          offsetYcenter: false,
          'font-family': 'Lobster',
          'font-size': '70px',
          'font-color': 'blue',
          'font-style': 'italic',
          'font-weight': 'bold',
        },
      },
      {
        text0: `IYFR Dnipro Squadron
        Of the Cherkasy Ukrainian Fleet`,
        location: {
          offsetX: 1754,
          offsetXcenter: true,
          offsetY: 1210,
          offsetYcenter: false,
          'font-family': 'Lobster',
          'font-size': '150px',
          'font-color': 'darkblue',
          'font-weight': 'bold',
          'font-style': 'italic',
          'font-weight': 'bold',
        },
      },
      {
        text0: `having met all of the qualifications is recognized as a 'Squadron1 of the Fellowship and is entitled 
        to all rights and honor thereto appertaining/ Witnes the pennnant and whell of the Fellowship and the 
        signature of its Officer this 12-th of March 2021`,
        location: {
          offsetX: 1754,
          offsetXcenter: false,
          offsetY: 1596,
          offsetYcenter: false,
          'font-family': 'Lobster',
          'font-size': '70px',
          'font-color': 'blue',
          'font-style': 'italic',
          'font-weight': 'bold',
        },
      },
      {
        text0: `Signed:`,
        location: {
          offsetX: 2283,
          offsetXcenter: false,
          offsetY: 1948,
          offsetYcenter: false,
          'font-family': 'Arial',
          'font-size': '60px',
          'font-color': 'blue',
          'font-style': 'italic',
          'font-weight': 'bold',
        },
      },
      {
        text0: `Robert Burns, International Commodore 2019 - 21`,
        location: {
          offsetX: 2333,
          offsetXcenter: false,
          offsetY: 2177,
          offsetYcenter: false,
          'font-family': 'Arial',
          'font-size': '60px',
          'font-color': 'blue',
          'font-style': 'italic',
          'font-weight': 'bold',
        },
      },
    ],
  };
  
  export default data;
  