
const key = 'rotarianAppGUID';

const generateRandomString = (length) => {
    const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const checkOrCreateGUID = () => {
    if (!localStorage.getItem(key)) {
        const guid = generateRandomString(12);
        localStorage.setItem(key, guid);
    }
}

export const getRotarianAppGUID = () => {
    checkOrCreateGUID();
    return localStorage.getItem(key);
}