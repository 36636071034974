import React, {useState, useEffect, useContext} from 'react';
import './AppBar.css';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { FormattedMessage } from 'react-intl';
import { LocalContext } from '../modules/LanguageProvider/LanguageContextProvider';
import { LOCALES } from '../i18n/locales';


import KPI_Logo from '../assets/img/KPI_Logo.png';

const Appbar = () => {
    const [userProfile, setUserProfile] = useState(null);
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
   
    const handleLanguageClick = () => {
        console.log("Клик по кнопке языка");
        setCurrentLocale(currentLocale === LOCALES.UKRAINIAN ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
    };

    const onLoginSuccess = (userData) => {
        setUserProfile(userData);
    }

    const onLogoutSuccess = () => {
        setUserProfile(null);
    }

    return (
        <AppBar position="static" className="primary-app-bar">
          <Toolbar className="app-bar-content">
            <Box className='menu-line'>
                <Box className="logo-line">
                    <img src={KPI_Logo} height='50px' />
                </Box>
                <Box className="title-line">
                    <h2>Brand Center UA</h2>
                </Box>
                <Button color="inherit" onClick={handleLanguageClick}>{currentLocale==='en-US'?'укр':'eng'}</Button>
                {/*<AccountMenu onLoginSuccess={onLoginSuccess} onLogoutSuccess={onLogoutSuccess} />*/}
            </Box>
          </Toolbar>
        </AppBar>
    )
}

export default Appbar;