import React, {useState, useEffect} from 'react';
import { Button, Slider } from '@mui/material';
import './SliderContainer.css';

const SliderContainer = ({
    id,
    value,
    onChange,
    defaultValue,
    min,
    max,
    step,
    label,
  }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    return (
        
      <div className="slider-container">
      {isMobile ? (
        <>
          <div className="slider-container1">
            <div className="slider-label">
              <label htmlFor={id}>{label}</label>
            </div>
            <div className="slider-value">{value}</div>
          </div>
          <div className="slider-container2">
            <Slider
              label={label}
              id={id}
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              aria-label="Ползунок"
              min={min}
              max={max}
              step={step}
              
            />
          </div>
        </>
      ) : (
        <div className="slider-container1">
          <div className="slider-label">
            <label htmlFor={id}>{label}</label>
          </div>
          <Slider
            id={id}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            aria-label="Ползунок"
            min={min}
            max={max}
            step={step}
            className="slider-mui"
          />
          <div className="slider-value">{value}</div>
        </div>
      )}
        </div>
        
    )
  };

  export default SliderContainer;