import React, { useState, useEffect } from 'react';

import Main from '../components/Main';
import OverlayImagesComponent_DB from '../components/AvatarMakerPage/OverlayImagesComponent_DB'; 

import KPI_OVERLAY from '../assets/img/T10_1.png';
import KPI_OVERLAY_2 from '../assets/img/KPI_2324_6.png';
import KPI_GRAY_PEOPLE from '../assets/img/tamplate-overlay.png';

const data = {
    id: 0,
    titele: "День Незаледності",
    year: "24-25",
    arr: [
        {
            eng: 'https://7fc65ea596c38701.cdn.express/secure/xv9nS7R_EbrqEkf7XDD6xw/clubsitecreator/brendcenter/dayindepends/q1_eng.png',
            ukr: 'https://7fc65ea596c38701.cdn.express/secure/7qiz4eE1BJXl2fqWMJv4aQ/clubsitecreator/brendcenter/dayindepends/q1_ukr.png',
        },
        {
            eng: 'https://7fc65ea596c38701.cdn.express/secure/7lhMPR9QHHEns2RwLVP6uQ/clubsitecreator/brendcenter/dayindepends/q2.png',
        },
        {
            eng: 'https://7fc65ea596c38701.cdn.express/secure/jpzciYvEraExEBzCri0MSQ/clubsitecreator/brendcenter/dayindepends/q3_eng.png',
            ukr: 'https://7fc65ea596c38701.cdn.express/secure/x8L8XkU7GLxlGG975-AYyw/clubsitecreator/brendcenter/dayindepends/q3_ukr.png',
        },
    ]
}

const AvatarMakerPage9 = () => {
    return (
        <>
        <Main>
            <OverlayImagesComponent_DB 
                data={data}
                img={KPI_OVERLAY} 
                img2={KPI_OVERLAY_2} 
                imgGrayPepole={KPI_GRAY_PEOPLE}
                defSize='large'
                cloudFolder="IndependedDay24"
                needCircle={true} />
        </Main>
        </>
    )
}

export default AvatarMakerPage9;