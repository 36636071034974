import React, {useState, useEffect, useContext} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import './Main.css'; // Підключення зовнішнього CSS-файлу

import { useLocation } from 'react-router-dom';

import LanguageContext from '../modules/LanguageProvider/LanguageContext';

import Appbar from './AppBar';
import Header from './Header';
import App from '../App';

const ga4 = 'UA-149587464-1';


const theme = createTheme({
  /*palette: {
    mode: 'light',
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#aaaaaa',
    },
  },*/
});

/*export interface IUserProfile {
  userName: string | undefined,
  email: string | undefined,
  imgUrl: string | undefined,
  committee: ISuperUserProfile | null,
};*/

const Main = ({children}) => {
  const location = useLocation();
  useEffect(() => {
    if (true || process.env.NODE_ENV === 'production') {
        window.gtag('config', ga4, {
        page_path: location.pathname,
        });
    }
  }, [location]);

 
  return (
    <LanguageContext>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container className="container">
      <div className="main-container">
        
        <Appbar />
        
      {children}
        
        <Header />
      </div>
      </Container>
    </ThemeProvider>
    </LanguageContext>
  );
};

export default Main;
