import React, { useState, useEffect } from 'react';
import { Box, TextField, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import FontControls from './FontControls';
import OffsetControl from './OffsetControl';

const TextEditor = ({ element, onElementUpdate, pageSize }) => {
  const [localElement, setLocalElement] = useState(element);

  useEffect(() => {
    setLocalElement(element);
  }, [element]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedElement = { ...localElement, [name]: value };
    setLocalElement(updatedElement);
    onElementUpdate(updatedElement);
  };

  const handleOffsetChange = (name, value) => {
    const updatedElement = { ...localElement };
    updatedElement.location[name] = value;
    if (name === 'offsetX' && localElement.location.offsetXcenter) {
      updatedElement.location.offsetX = (pageSize.width - (updatedElement.width || 0)) / 2;
    } else if (name === 'offsetY' && localElement.location.offsetYcenter) {
      updatedElement.location.offsetY = (pageSize.height - (updatedElement.height || 0)) / 2;
    }
    setLocalElement(updatedElement);
    onElementUpdate(updatedElement);
  };

  const handleCenterChange = (name, checked) => {
    const updatedElement = { ...localElement };
    updatedElement.location[`${name}center`] = checked;
    setLocalElement(updatedElement);
    handleOffsetChange(name, checked ? (name === 'offsetX' ? (pageSize.width - (localElement.width || 0)) / 2 : (pageSize.height - (localElement.height || 0)) / 2) : localElement.location[name]);
  };

  const handleFontChange = (fontData) => {
    const updatedElement = { ...localElement, location: { ...localElement.location, ...fontData } };
    setLocalElement(updatedElement);
    onElementUpdate(updatedElement);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <TextField
        label="Text"
        name="text0"
        value={localElement.text0}
        onChange={handleChange}
      />
      <OffsetControl
        label="Offset X"
        name="offsetX"
        value={localElement.location.offsetX}
        max={pageSize.width}
        isCenter={localElement.location.offsetXcenter}
        onCenterChange={handleCenterChange}
        onValueChange={handleOffsetChange}
      />
      <OffsetControl
        label="Offset Y"
        name="offsetY"
        value={localElement.location.offsetY}
        max={pageSize.height}
        isCenter={localElement.location.offsetYcenter}
        onCenterChange={handleCenterChange}
        onValueChange={handleOffsetChange}
      />
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Font Controls</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FontControls
            fontData={localElement.location}
            onFontChange={handleFontChange}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

TextEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onElementUpdate: PropTypes.func.isRequired,
  pageSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default TextEditor;
