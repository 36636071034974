import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import axios from 'axios';

const SERVER = 'http://localhost:3001';
//const SERVER = 'https://apitools.rotaryd2232ukraine.org'

const VideoProcessor = () => {
    const [video, setVideo] = useState(null);
    const [downloadUrl, setDownloadUrl] = useState('');

    const handleFileDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0 && files[0].type.startsWith('video')) {
            setVideo(files[0]);
        }
    };

    const handleExtractAudio = async () => {
        console.log('handleExtractAudio start');
        if (!video) return;

        const formData = new FormData();
        formData.append('video', video);

        try {
            const response = await axios.post(SERVER+'/api/extract-audio', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            setDownloadUrl(url);
            console.log('handleExtractAudio finish');
        } catch (error) {
            console.error('Error extracting audio:', error);
        }
    };

    const handleExtractVideo = async () => {
        if (!video) return;

        const formData = new FormData();
        formData.append('video', video);

        try {
            const response = await axios.post('/api/extract-video', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            setDownloadUrl(url);
        } catch (error) {
            console.error('Error extracting video:', error);
        }
    };

    return (
        <Box onDrop={handleFileDrop} onDragOver={(e) => e.preventDefault()} sx={{ border: '1px dashed gray', padding: 2, textAlign: 'center' }}>
            {video ? <p>Файл завантажено: {video.name}</p> : <p>Перетягніть відеофайл сюди</p>}
            <Button variant="contained" onClick={handleExtractAudio} disabled={!video}>
                Витягти аудіо
            </Button>
            <Button variant="contained" onClick={handleExtractVideo} disabled={!video} sx={{ ml: 2 }}>
                Витягти відео
            </Button>
            {downloadUrl && (
                <Box mt={2}>
                    <Button variant="outlined" href={downloadUrl} download>
                        Завантажити результат
                    </Button>
                </Box>
            )}
        </Box>
    );
}
export default VideoProcessor;
