import React, { useState, useEffect } from 'react';
import Controls from '../../modules/AvatarMaker/Controls';
import Preview from '../../modules/AvatarMaker/Preview';
import { Button, Container, Input, Typography } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { Box, Grid, TextField, Card, CardContent } from '@mui/material';
import Alert from '@mui/material/Alert';

import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import './OverlayImages.css';
import {overlayImages} from './OverlayImagesTools';
import {uploadImage} from '../../modules/AvatarMaker/cloudinaryUpload';
import StepLine from '../../modules/AvatarMaker/StepLine';

import {getRotarianAppGUID} from '../../tools/rotarianAppGUID/rotarianAppGUID';
import SelectedImageLibraryComponent from './SelectedImageLibraryComponent';

import TEMPLATE_OVERLAY from '../../assets/img/tamplate-overlay.png';

const OverlayImagesComponent = ({img, img2, stepLanguage, cloudFolder, copiright, imgGrayPepole, defSize, needCircle=true}) => {
    const intl = useIntl();

    const [jpgImage, setJpgImage] = useState(imgGrayPepole ? imgGrayPepole : TEMPLATE_OVERLAY);
    //const [pngImage, setPngImage] = useState('');
    const [overlayedImage, setOverlayedImage] = useState('');
    //const [fileName, setFileName] = useState('');
    const [currentImageParams, setCurrentImageParams] = useState({
        imageUrl: null,
        scale: 0, 
        offsetx: 0, 
        offsety: 0,
    });
    const [autoRedraw, setAutoRedraw] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [selectedSize, setSelectedSize] = useState(defSize ? defSize : 'large');
    const [isPendingRedraw, setIsPendingRedraw] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedVariantImg, setSelectedVariantImg] = useState('option_img1');
    const [expanded, setExpanded] = useState(true);
    const [anchorElColor, setAnchorElColor] = useState(null);
    const [selectedOptionCustomMode, setSelectedOptionCustomMode] = useState('');
    const [country, setCountry] = React.useState("");
    const [district, setDistrict] = React.useState("");

    const [bgcolor, setBgColor] = useState('gray');
    const [openAlert, setOpenAlert] = React.useState(false);
    
    const [rotarianGUID, setRotarianGUID] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const rg = await getRotarianAppGUID();
                setRotarianGUID(rg);
            } catch (error) {
                console.log("IccPage get ICC list error="+error);
            }
            
        };
        fetchData();
        setSelectedOption("Укр");

        return () => {
        //clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        redrawOverlay();
    }, [jpgImage]);

    useEffect(() => {
        redrawOverlay();
    }, [currentImageParams, selectedOption, selectedOptionCustomMode, selectedOptionCustomMode, country, district, bgcolor, selectedVariantImg]);

    

    const handleOffsetXChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setCurrentImageParams(prevParams => ({
            ...prevParams,
            offsetx: value
        }));
        setIsPendingRedraw(true);
    };

    const handleOffsetYChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setCurrentImageParams(prevParams => ({
            ...prevParams,
            offsety: value
        }));
        setIsPendingRedraw(true);
    };

    const handleScaleChange = (event) => {
        const value = parseFloat(event.target.value);
        setCurrentImageParams(prevParams => ({
            ...prevParams,
            scale: value
        }));
        setIsPendingRedraw(true);
    };

    const handleAutoRedrawChange = () => {
        setAutoRedraw(!autoRedraw);
        if (!autoRedraw) {
        setIsPendingRedraw(true);
        }
        console.log("AutoRedrawChange = "+autoRedraw.toString());
    };

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
        setIsPendingRedraw(true);
    };

    const handleDownload = async () => {
        ///?saveImgParams();
        //uploadImage(overlayedImage);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'overlayed_image.jpg';
        link.click();

        const res = await uploadImage(overlayedImage, 'AvatarMakers/'+cloudFolder);
        //uploadFileToPhotoLibrary(downloadUrl, rotarianGUID+'/result');
        setOpenAlert(true);
    };

    const handleRedrawOverlay = () => {
        redrawOverlay();
        console.log("redrav pressed");
    };

    const redrawOverlay = () => {
        const pngImage = getImg();
        overlayImages(jpgImage, pngImage, selectedSize, 
            currentImageParams.scale, 
            currentImageParams.offsetx, 
            currentImageParams.offsety, 
            country, district, bgcolor, selectedOptionCustomMode, setOverlayedImage, setDownloadUrl, 
            needCircle);
        setIsPendingRedraw(false);
        console.log("redrawOverlay");
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        console.log("handleOptionChange = "+event.target.value);
    };

    const handleVariantImgChange = (event) => {
        setSelectedVariantImg(event.target.value);
        //setOverlayedImage(selectedVariantImg==="option_img1" ? img : img2)
        console.log("handleVariantImgChange = "+event.target.value);
    };

    const handleCopyClick = async () => {
        window.gtag('event', 'click', {
        'event_category': 'button',
        'event_label': 'brandcenter-download-image',
        });

        try {
        await navigator.clipboard.writeText('4441 1144 0294 9042');
        alert('Номер картки скопійовано в буфер обміну!');
        } catch (error) {
        console.error('Помилка копіювання в буфер обміну:', error);
        }
    };

    const getImg = () => {
        if (stepLanguage && stepLanguage.items) {
            return selectedOption === 'option_ukr' ? stepLanguage.items[1].img : stepLanguage.items[0].img; 
        }
        if (img && img2) {
            return selectedVariantImg==="option_img1" ? img : img2;
        }
        if (img) {
            return img;
        }
    }

    const handleCurrentImageParams = async(currentImageParams) => {
        setCurrentImageParams(currentImageParams)
        //setJpgImage(currentImage);//Blob

        if (currentImageParams.imageFile) {
            const file = currentImageParams.imageFile;
            const allowedExtensions = ['jpg', 'jpeg', 'png'];
          
            if (file) {
              const extension = file.name.split('.').pop().toLowerCase();
          
              if (allowedExtensions.includes(extension)) {
                //setFileName(file);
                const reader = new FileReader();
          
                reader.onload = (e) => {
                  setJpgImage(e.target.result);
                };
          
                reader.readAsDataURL(file);
            } else {
                alert(`Дозволені тільки файли з розширеннями ${allowedExtensions.join(', ')}. Ви вибрали файл з розширенням ${extension}.`);
            }
            }
        } else {
            // Завантажуємо зображення як Blob
            const response = await fetch(currentImageParams.imageUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            // Читаємо Blob і перетворюємо в Data URL
            let currentImage = null;
            const reader = new FileReader();
            reader.onload = (e) => {
                setJpgImage(e.target.result);
                console.log('setJpgImage success');
            };
            reader.readAsDataURL(blob);
        }
        
    }

  const open = Boolean(anchorElColor);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Container>
        
        {/*rotarianGUID={rotarianGUID}
        <br/>
        photoLibraryPhotoUrls={photoLibraryPhotoUrls}
        <br/>
        fileName={downloadUrl}
        imgparams={JSON.stringify(currentImageParams)}*/}

        <Box
          sx={{
            backgroundColor: 'darkblue',
            color: 'white',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '12px'
          }}
        >
          <Typography variant='h3' justifyContent='center' >Postcard Maker</Typography>
        </Box>
        
        <StepLine curStep='1' title='step1' stepCount='4' >
            <SelectedImageLibraryComponent 
                rotarianGUID={rotarianGUID} 
                currentImageParams={currentImageParams}
                handleCurrentImageParams={handleCurrentImageParams} 
            />

        </StepLine>

        <StepLine curStep='2' title='step2' stepCount='4' >
          
          <Controls
            offsetX={currentImageParams.offsetx}
            offsetY={currentImageParams.offsety}
            scale={currentImageParams.scale}
            autoRedraw={autoRedraw}
            selectedSize={selectedSize}
            handleOffsetXChange={handleOffsetXChange}
            handleOffsetYChange={handleOffsetYChange}
            handleScaleChange={handleScaleChange}
            handleAutoRedrawChange={handleAutoRedrawChange}
            handleSizeChange={handleSizeChange}
            handleOverlayImages={handleRedrawOverlay}
          />
        </StepLine>

        {stepLanguage && 
            <StepLine curStep='3' title='step3' stepCount='4' >
            <FormControl component="fieldset" className="radio-group-container">
                <RadioGroup value={selectedOption} onChange={handleOptionChange} className="radio-group">
                <FormControlLabel value="option_ukr" control={<Radio />} label="Укр" />
                <FormControlLabel value="option_eng" control={<Radio />} label="Eng" />
                </RadioGroup>
            </FormControl>
            </StepLine>
        }

        {/* {img2 &&
            <StepLine curStep='3' title='step3' stepCount='4' >
            <FormControl component="fieldset" className="radio-group-container">
                <RadioGroup value={selectedVariantImg} onChange={handleVariantImgChange} className="radio-group">
                <FormControlLabel value="option_img1" control={<Radio />} label="Варіант 1" />
                <FormControlLabel value="option_img2" control={<Radio />} label="Варіант 2" />
                </RadioGroup>
            </FormControl>
            </StepLine>
        } */}

        <Preview overlayedImage={overlayedImage} handleDownload={handleDownload} />
        {copiright}

        <StepLine curStep='4' title='step5' stepCount='4'  >
            <Box display="flex">
            <Button variant="contained" component="span" onClick={handleDownload}>
              <FormattedMessage id={'step_download'} />
            </Button>
            <Collapse in={openAlert}>
            <Alert
                severity="success"
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setOpenAlert(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
            >
                <FormattedMessage id={'alert1_message1'} />
                <FormattedMessage id={'alert1_message2'} />
            </Alert>
            </Collapse>
            </Box>
        </StepLine>

        

      </Container>
    </>
  );
};


export default OverlayImagesComponent;