import React, {useState, useEffect} from 'react';
import { Button, Slider, Box } from '@mui/material';
import './StepLine.css';

import { FormattedMessage } from 'react-intl';

const StepLine = (props) => {
    return (
      <>
      <Box className="step-line">
        <Box className="label-line"><h3><FormattedMessage id='step_msg1' /> {props.curStep} <FormattedMessage id='step_msg2' /> {props.stepCount}: </h3>  </Box>
        <Box><FormattedMessage id={props.title} /></Box>
      </Box>
      <Box>{props.children}</Box>
      </>
    );
};

export default StepLine;