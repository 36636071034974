import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ZoomControls = ({ zoom, onZoomChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography variant="h6">Zoom: {zoom}%</Typography>
      <TextField
        label="Zoom"
        type="number"
        value={zoom}
        onChange={(e) => onZoomChange(e)}
        inputProps={{ min: 10, max: 500 }}
      />
    </Box>
  );
};

ZoomControls.propTypes = {
  zoom: PropTypes.number.isRequired,
  onZoomChange: PropTypes.func.isRequired,
};

export default ZoomControls;
