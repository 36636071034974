import React, { useState } from 'react';
import { Button, TextField, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';

//const SERVER = 'http://localhost:3001';
const SERVER = 'https://apitools.rotaryd2232ukraine.org'

const TextOverlayComponent = () => {
    const [imageUrl, setImageUrl] = useState('https://api.rotary.org.ua/uploads/brandcenter/bot_RotaryBasicUA/bot_sertificate.png');
    const [text, setText] = useState('Your Text');
    const [x, setX] = useState(0);
    const [y, setY] = useState(430);
    const [centerX, setCenterX] = useState(true);
    const [centerY, setCenterY] = useState(false);
    const [resultImageUrl, setResultImageUrl] = useState('');

    const handleSubmit = async () => {
        const requestBody = {
            imageUrl,
            text,
            position: {
                x: centerX ? 'center' : parseInt(x),
                y: centerY ? 'center' : parseInt(y)
            }
        };

        try {
            const response = await fetch(SERVER+'/api/overlay-text', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
            const data = await response.json();
            if (data.imageUrl) {
                setResultImageUrl(data.imageUrl);
            } else {
                alert('Не вдалося накласти текст на зображення.');
            }
        } catch (error) {
            console.error('Помилка:', error);
            alert('Помилка при відправці запиту до сервера.');
        }
    };


    const requestBody = {
        imageUrl,
        text,
        position: {
            x: centerX ? 'center' : parseInt(x),
            y: centerY ? 'center' : parseInt(y)
        }
    };
    return (
        <Box sx={{ maxWidth: 500, m: 'auto', p: 3 }}>
            <Typography variant="h6">Накладання тексту на зображення</Typography>
            <TextField
                fullWidth
                label="URL зображення"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
                margin="normal"
            />
            <TextField
                fullWidth
                label="Текст"
                value={text}
                onChange={(e) => setText(e.target.value)}
                margin="normal"
            />
            <TextField
                fullWidth
                label="X координата"
                type="number"
                value={x}
                onChange={(e) => setX(e.target.value)}
                margin="normal"
                disabled={centerX}
            />
            <FormControlLabel
                control={<Checkbox checked={centerX} onChange={(e) => setCenterX(e.target.checked)} />}
                label="По центру X"
            />
            <TextField
                fullWidth
                label="Y координата"
                type="number"
                value={y}
                onChange={(e) => setY(e.target.value)}
                margin="normal"
                disabled={centerY}
            />
            <FormControlLabel
                control={<Checkbox checked={centerY} onChange={(e) => setCenterY(e.target.checked)} />}
                label="По центру Y"
            />

            <br/>
            <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>Накласти текст</Button>
            <br/>API_REQUEST: 
            <br/>POST_URL = {SERVER+'/api/overlay-text'}
            <br/>body = {JSON.stringify(requestBody)}
            <br/>RESPONSE in json().imageUrl
            <br/>
            {resultImageUrl && (
                <Box mt={2}>
                    <Typography variant="h6" mt={2}>Результат:</Typography>
                    <img src={resultImageUrl} alt="Результат зображення" style={{ maxWidth: '100%' }} />
                </Box>
            )}
        </Box>
    );
};

export default TextOverlayComponent;
