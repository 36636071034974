const ENV_TYKO_UPLOAD_PRESET = 'lto2m1qq';

export const uploadImage = async (profileImage, folder) => {
    //e.preventDefault();
    //setIsLoading(true);
    console.log("uploadImage uploading...");
    try {
      let imageURL;
      if (
        profileImage /*&& (
          profileImage.type === 'image/png' || 
          profileImage.type === 'image/jpg' || 
          profileImage.type === 'image/jpeg' 
          )*/
        ) {
          const image = new FormData();
          image.append('file', profileImage);
          image.append('clode_name', 'tyko');
          image.append('upload_preset', ENV_TYKO_UPLOAD_PRESET);
          image.append("folder", folder);
          console.log("uploadImage folder="+folder);

          const response = await fetch(
            "https://api.cloudinary.com/v1_1/tyko/upload",
            {
              method: "post",
              body: image,
            }
          )
          //setIsLoading(true);
          const imgData = await response.json();
          imageURL = imgData.url;
          //setImagePreview(null);
          console.log("uploadImage uploaded");
          return true;
        }
        //alert(imageURL);
        console.log("uploadImage finished = "+imageURL);
    } catch (error) {
      console.log(error);
      //setIsLoading(true);
    }
    return false;
  }