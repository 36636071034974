import React, { useState, useEffect } from 'react';

import Main from '../components/Main';
import OverlayImagesComponent from '../components/AvatarMakerPage/OverlayImagesComponent'; 

import KPI_OVERLAY from '../assets/img/T10_1.png';
import KPI_OVERLAY_2 from '../assets/img/T10_1.png';
import KPI_GRAY_PEOPLE from '../assets/img/RI_B_template.png';

const AvatarMakerPage9 = () => {
    return (
        <>
        <Main>
            <OverlayImagesComponent 
                img={KPI_OVERLAY} 
                img2={KPI_OVERLAY_2} 
                imgGrayPepole={KPI_GRAY_PEOPLE}
                defSize='large'
                cloudFolder="119RiBirthday"
                needCircle={false} />
        </Main>
        </>
    )
}

export default AvatarMakerPage9;