
import Box from '@mui/material/Box';

import { FormattedMessage } from 'react-intl';

const Header = () => {

    return (
        <Box className="contact-box">
          <Box className="contact-box-left"><FormattedMessage id={'footer_msg1'} /></Box>
          <Box className="contact-box-right">Online service developed by <span className="tyko-devstudio">Tyko:DevStudio</span></Box>
        </Box>
    )
}

export default Header;