import React, { useState, useEffect } from 'react';
import { TextField, Box, Slider, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ImageEditor = ({ element, onElementUpdate, pageSize }) => {
  const [localElement, setLocalElement] = useState(element);

  useEffect(() => {
    setLocalElement(element);
  }, [element]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedElement = { ...localElement, [name]: value };
    setLocalElement(updatedElement);
    onElementUpdate(updatedElement);
  };

  const handleLocationChange = (name, value) => {
    let updatedValue = value;
    if (value === 'center') {
      if (name === 'offsetX') {
        updatedValue = (pageSize.width - (localElement.width || 0)) / 2;
      } else if (name === 'offsetY') {
        updatedValue = (pageSize.height - (localElement.height || 0)) / 2;
      }
    }
    const updatedElement = {
      ...localElement,
      location: { ...localElement.location, [name]: updatedValue },
    };
    setLocalElement(updatedElement);
    onElementUpdate(updatedElement);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <TextField
        label="Image URL"
        name="imgurl"
        value={localElement.imgurl}
        onChange={handleChange}
      />
      <Typography gutterBottom>Offset X</Typography>
      <Slider
        name="offsetX"
        value={Number(localElement.location.offsetX)}
        min={0}
        max={pageSize.width}
        onChange={(e, value) => handleLocationChange('offsetX', value)}
        valueLabelDisplay="auto"
      />
      <TextField
        label="Offset X"
        name="offsetX"
        value={localElement.location.offsetX}
        onChange={handleChange}
      />
      <Typography gutterBottom>Offset Y</Typography>
      <Slider
        name="offsetY"
        value={Number(localElement.location.offsetY)}
        min={0}
        max={pageSize.height}
        onChange={(e, value) => handleLocationChange('offsetY', value)}
        valueLabelDisplay="auto"
      />
      <TextField
        label="Offset Y"
        name="offsetY"
        value={localElement.location.offsetY}
        onChange={handleChange}
      />
      <TextField
        label="Scale"
        name="scale"
        value={localElement.location.scale}
        onChange={handleChange}
      />
    </Box>
  );
};

ImageEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onElementUpdate: PropTypes.func.isRequired,
  pageSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default ImageEditor;
