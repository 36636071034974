import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';

const JsonStructureViewer = ({ data, onElementSelect }) => (
  <List>
    <ListItem button onClick={() => onElementSelect(data.bgimg, -1)}>
      <ListItemText primary="Background Image" />
    </ListItem>
    {data.layer.map((element, index) => (
      <ListItem button key={index} onClick={() => onElementSelect(element, index)}>
        <ListItemText
          primary={element.imgurl ? `Image ${index + 1}` : `Text ${index + 1}`}
        />
      </ListItem>
    ))}
  </List>
);

JsonStructureViewer.propTypes = {
  data: PropTypes.object.isRequired,
  onElementSelect: PropTypes.func.isRequired,
};

export default JsonStructureViewer;
