import React from 'react';

import Main from '../components/Main';
import VideoProcessor from '../modules/AvatarMaker/VideoProcessor'; 

const VideoDubbing = () => {;
  
    return (
        <>
        <Main>
            <VideoProcessor />
        </Main>
        </>
    )
}

export default VideoDubbing;