import React from 'react';
import { Box, TextField, Slider, Checkbox, FormControlLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const OffsetControl = ({ label, name, value, max, isCenter, onCenterChange, onValueChange }) => {
  const handleSliderChange = (e, newValue) => {
    onValueChange(name, newValue);
  };

  const handleInputChange = (e) => {
    onValueChange(name, parseFloat(e.target.value));
  };

  const handleCenterChange = (e) => {
    onCenterChange(name, e.target.checked);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography gutterBottom>{label}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <TextField
          label={label}
          name={name}
          value={value}
          onChange={handleInputChange}
          disabled={isCenter}
        />
        <Slider
          name={name}
          value={value}
          min={0}
          max={max}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          disabled={isCenter}
        />
        <FormControlLabel
          control={<Checkbox checked={isCenter} onChange={handleCenterChange} />}
          label="Center"
        />
      </Box>
    </Box>
  );
};

OffsetControl.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  max: PropTypes.number.isRequired,
  isCenter: PropTypes.bool.isRequired,
  onCenterChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default OffsetControl;
