
const brancenterPath = '/brandcenter/avatarmaker/';

export const getLibraryPhotos = async (rotarianGUID) => {
    const url = `https://api.rotary.org.ua/folders/photos${brancenterPath}${rotarianGUID}`;
    console.log('getLibraryPhotos url=' + url);

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const urls = await response.json();
        console.log('getLibraryPhotos2 url=' + urls);
        return urls;
    } catch (error) {
        console.error('Помилка при завантаженні фотографій:', error);
        return null;
    }
}

const uploadFile = (file, rotarianGUID) => {
    
};

/*
export const uploadFileToPhotoLibrary = async (file, rotarianGUID) => {
    const formData = new FormData();

    const subputh = 'uploads' + brancenterPath + rotarianGUID;
    console.log('uploadFile: subputh='+subputh);
    formData.append('path', subputh);
    formData.append('title', 'uploadTitle');

    //selectedPhotos.forEach((file, index) => {
      formData.append(`photos`, file);
    //});

    const apiUrl = 'https://api.rotary.org.ua/upload';

    await fetch(apiUrl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => { // data - is array of img urls
        console.log('uploadFileToPhotoLibrary data='+data);
        return data;
      })
      .catch((error) => {
        console.error('Помилка завантаження: ', error);
      });

    */
export const uploadFileToPhotoLibrary = async (file, rotarianGUID) => {
    const formData = new FormData();
    const subputh = 'uploads' + brancenterPath + rotarianGUID;
    console.log('uploadFile: subputh=' + subputh);

    formData.append('path', subputh);
    formData.append('title', 'uploadTitle');
    //selectedPhotos.forEach((file, index) => {
        formData.append(`photos`, file);
      //});

    const apiUrl = 'https://api.rotary.org.ua/upload';

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.text();
        console.log('uploadFileToPhotoLibrary data=' + data);
        if (data)
            return JSON.parse(data);
        else
            return null;
    } catch (error) {
        console.error('Помилка завантаження: ', error);
        return null;
    }
};
