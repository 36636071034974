import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { LOCALES } from '../../i18n/locales';
import { messages } from '../../i18n/massages';
import {LocalContext} from './LanguageContextProvider';


export function LanguageContext({ children }) {
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);

    return (
        <IntlProvider messages={messages[currentLocale]}
            locale={currentLocale}
            defaultLocale={LOCALES.ENGLISH}>
            {children}
            onError={() => {}}
        </IntlProvider> 
    )
}

export default LanguageContext;