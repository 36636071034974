import { LOCALES } from './locales'

export const messages = {
    [LOCALES.ENGLISH]: {
        menuTitle: `Rotary Ukraine`,
        step1: 'Select a file for the avatar',
        step2: 'Move and scale the photo to fit in the circle.',
        step3: 'Choose the language of the slogan',
        step4: '(optional) Advanced settings',
        step5: 'Download the resulting file and use it for your avatar.',
        step6: '(optional) Donate for using this service - for good deeds!',
        step_msg1: 'Step',
        step_msg2: 'of',
        
        button_file: 'Select file',
        button_download: '',
        fit_by_X: 'Fit by X:',
        fit_by_Y: 'Fit by Y:',
        scale: 'Scale:',

        adv_forD2232: 'for D2232',
        adv_for_custom: 'other district',
        adv_country: 'Country',
        adv_district: 'District',
        adv_bg: 'Background',
        step_download: 'Dowlload',
        donat_msg1: '5$ will be good!',
        donat_msg2: 'Copy the card number of the Ukrainian bank to the clipboard for payment',
        footer_msg1: 'If you have any questions, email to tykogm2@gmail.com Oleh Kosmyna',

        alert1_message1: 'The download was successful.',
        alert1_message2: "Check your browser's Downloads folder.",
        'або виберіть з завантажених': 'or select custom',
    },
    [LOCALES.UKRAINIAN]: {
        menuTitle: 'Ротарі Україна',
        step1: 'Виберіть файл для аватарки',
        step2: 'Рухайте та масштабуйте фото, щоб вписати в коло.',
        step3: 'Оберіть мову гасла',
        step4: "(необов'язковий) Розширені налаштування",
        step5: 'Завантажте результуючий файл, та використовуйте для аватарки.',
        step6: "(необов'язковий) Донат за користування сервісом - на добрі справи!",
        step_msg1: 'Крок',
        step_msg2: 'із',
        
        button_file: 'Обрати файл',
        button_download: '',
        fit_by_X: 'Змістити по X:',
        fit_by_Y: 'Змістити по Y',
        scale: 'Масштабувати:',

        adv_forD2232: 'для Д2232',
        adv_for_custom: 'для інших дистриктів',
        adv_country: 'Країна:',
        adv_district: 'Дистрикт:',
        adv_bg: 'Дистрикт:',
        step_download: 'Завантажити',
        donat_msg1: '5$ вітається!',
        donat_msg2: 'Скопіювати в буфер обміну номер банківської карти для розрахунку',
        footer_msg1: 'При виникненні питань, телефонуйте за номером 099-6332351(Telegram) Олег Косьмина',

        alert1_message1: 'Завнтаження пройшло успішно.',
        alert1_message2: 'Перегляньте папку Завантаження вашого браузера.',
        'або виберіть з завантажених': 'або виберіть з завантажених',
    },
}
