import React, { useState, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { Button, Container, Input, Typography } from '@mui/material';

import {findByAvatarParams, updateAvatarParams} from '../../tools/AvatarParams/AvatarParams';
import {uploadFileToPhotoLibrary, getLibraryPhotos} from '../../tools/PhotoLibrary/uploadFileToPhotoLibrary';

const SelectedImageLibraryComponent = ({rotarianGUID, currentImageParams, handleCurrentImageParams}) => {
    const [photoLibraryPhotoUrls, setPhotoLibraryPhotoUrls] = useState();
    const [selectedImgUrl, setSelectedImgUrl] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (rotarianGUID) {
                    const pu = await getLibraryPhotos(rotarianGUID);
                    console.log('getLibraryPhotos4 url='+pu);
                    setPhotoLibraryPhotoUrls(pu);
                }
            } catch (error) {
                console.log("IccPage get ICC list error="+error);
            }
            
          };
          fetchData();
        return () => {
          //clearInterval(timer);
        };
    }, [rotarianGUID]);

    const saveImgParamsBD = (selimgurl) => {
        
        if (selimgurl) {
            updateAvatarParams(rotarianGUID, selimgurl, currentImageParams);
        } else {
            console.log('ERROR: saveImgParamsBD');
        }
    }

    const handleJpgImageUpload = async (event) => {
        const file = event.target.files[0];
        console.log('======== file='+file.name)
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
      
        if (file) {
          const extension = file.name.split('.').pop().toLowerCase();
      
          if (allowedExtensions.includes(extension)) {
            handleCurrentImageParams({
                imageUrl: file.name,
                imageFile: file,
                scale: 0, 
                offsetx: 0, 
                offsety: 0,
            })

            /*setFileName(file);
    
            const reader = new FileReader();
            reader.onload = (e) => {
              setJpgImage(e.target.result);
            };
            reader.readAsDataURL(file);*/
            
            //upload file, awaite, anf reload list
            const data = await uploadFileToPhotoLibrary(file, rotarianGUID);
            console.log('uploadFileToPhotoLibrary2 data='+data)
            if (data ) {//&& data.length>0 && data[0].imgs && data[0].imgs.length>0) {
                const pathname = 'https://api.rotary.org.ua/'+data.imgs[0].path;
                console.log('==== pathname='+pathname);
                setSelectedImgUrl(pathname);
            }
            const pu = await getLibraryPhotos(rotarianGUID);
            console.log('getLibraryPhotos4 url='+pu);
            setPhotoLibraryPhotoUrls(pu);
          } else {
            alert(`Дозволені тільки файли з розширеннями ${allowedExtensions.join(', ')}. Ви вибрали файл з розширенням ${extension}.`);
          }
        }
    };

    const handleSelectImg = async (selimgurl) => {
        saveImgParamsBD(currentImageParams.imageUrl);

        ///setFileName(selimgurl);
        console.log('handleSelectImg selimgurl='+selimgurl);

        try {
            const imgparam = await findByAvatarParams(rotarianGUID, selimgurl);
            console.log('handleSelectImg imgparam='+JSON.stringify(imgparam));
            if (imgparam && imgparam.length>0) {
                console.log('handleSelectImg1 setScale='+imgparam[0].scale);
                handleCurrentImageParams({
                    imageUrl: selimgurl,
                    imageFile: null,
                    scale: imgparam[0].scale, 
                    offsetx: imgparam[0].offsetx, 
                    offsety: imgparam[0].offsety,
                })
                
                setSelectedImgUrl(selimgurl);
            } else {
                console.log('handleSelectImg2');
                handleCurrentImageParams({
                    imageUrl: selimgurl,
                    imageFile: null,
                    scale: 0, 
                    offsetx: 0, 
                    offsety: 0,
                })
                
                setSelectedImgUrl(selimgurl);
            }

        } catch (error) {
            console.error('Помилка при завантаженні налаштувань зображення:', error);
        }
    }

    return (
        <>
        
        {/*imgparams2={JSON.stringify(currentImageParams)}*/}
        <label htmlFor="upload-button">
            <Button variant="contained" component="span" >
                <FormattedMessage id={'button_file'} />
            </Button>
            <Input
                id="upload-button"
                type="file"
                accept=".jpeg"
                style={{ display: 'none' }}
                onChange={handleJpgImageUpload}
            />
        </label> <FormattedMessage id={'або виберіть з завантажених'} /> 
        {photoLibraryPhotoUrls && 
            photoLibraryPhotoUrls.map((item, index) => (
            <Button key={index} onClick={() => handleSelectImg(item)} 
                style={{border:  selectedImgUrl===item ? "2px solid yellow" : "0px solid red" }}>
                <img src={item} height='50px' />
            </Button>
        ))}
        </>
    )
}

export default SelectedImageLibraryComponent;