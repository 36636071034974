import React from 'react';

import Main from '../components/Main';
import TextOverlayComponent from '../modules/AvatarMaker/TextOverlayComponent'; 

const CertificateMaker4 = () => {;
  
    return (
        <>
        <Main>
            <TextOverlayComponent />
        </Main>
        </>
    )
}

export default CertificateMaker4;