
const BASE_URL = 'https://api.rotary.org.ua/apibd';

function getFileNameFromUrl(url) {
    if (url) {
        console.log('====getFileNameFromUrl url='+JSON.stringify(url));
        const parts = url.split('/');
        return parts[parts.length - 1];
    } else {
        return null;
    }
}

export const updateAvatarParams = async (rguid, filename, data) => {
    const fn = getFileNameFromUrl(filename);
    console.log(`updateAvatarParams rguid=${rguid}, filename=${fn}, scale=${data.scale}, offsetx=${data.offsetx}, offsety=${data.offsety}`);
    try {
        const response = await fetch(`${BASE_URL}/brancenter/avatar/${rguid}/${fn}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Помилка при оновленні параметрів:', error);
    }
};


export const findByAvatarParams = async (rguid, filename) => {

    try {
        const response = await fetch(`${BASE_URL}/brancenter/avatar/${rguid}/${getFileNameFromUrl(filename)}`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Помилка при пошуку параметрів:', error);
    }
};
