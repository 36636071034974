import React from 'react';
import PropTypes from 'prop-types';
import ImageEditor from './ImageEditor';
import TextEditor from './TextEditor';

const ElementEditor = ({ element, onElementUpdate, pageSize }) => {
  return element.imgurl !== undefined ? (
    <ImageEditor element={element} onElementUpdate={onElementUpdate} pageSize={pageSize} />
  ) : (
    <TextEditor element={element} onElementUpdate={onElementUpdate} pageSize={pageSize} />
  );
};

ElementEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onElementUpdate: PropTypes.func.isRequired,
  pageSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default ElementEditor;
