

import KPI_OVERLAY_CUSTOM from '../../assets/img/KPI_2324_custom.png';

export const overlayCertificateImages = (
        jpgImage, pngImage, 
        scale, offsetX, offsetY, userProfile, 
        bgcolor, selectedOptionCustomMode, setOverlayedImage, setDownloadUrl) => {

    //drawCertifacate(jpgImage, pngImage);
    //const drawCertifacate = (userImg, certificateImg) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const jpgImg = new Image();
        jpgImg.src = jpgImage;
        jpgImg.onload = () => {

            let maxWidth, maxHeight;
            maxWidth = 1004;
            maxHeight = 650;

            const imageRatio = jpgImg.width / jpgImg.height;
            let targetWidth = maxWidth;
            let targetHeight = maxHeight;

            if (imageRatio > maxWidth / maxHeight) {
                targetHeight = maxWidth / imageRatio;
            } else {
                targetWidth = maxHeight * imageRatio;
            }

            canvas.width = maxWidth;
            canvas.height = maxHeight;

            // Set green background if necessary
            //if (targetWidth !== maxWidth || targetHeight !== maxHeight) {
                context.fillStyle = 'white';
                context.fillRect(0, 0, maxWidth, maxHeight);
            //}
            console.log('fillStyle = '+bgcolor);

            // Scale image
            const scaledWidth = targetWidth * (1 + scale / 10);
            const scaledHeight = targetHeight * (1 + scale / 10);
            const offsetXWithScale = (maxWidth - scaledWidth) / 2 + offsetX;
            const offsetYWithScale = (maxHeight - scaledHeight) / 2 + offsetY;
            context.drawImage(jpgImg, 0, 0, jpgImg.width, jpgImg.height, offsetXWithScale, offsetYWithScale, scaledWidth, scaledHeight);

            // Offset image
            const pngImg = new Image();
            pngImg.src = pngImage ? pngImage : KPI_OVERLAY_CUSTOM ;

            pngImg.onload = () => {
                //context.globalAlpha = 0.9;
                context.drawImage(pngImg, 0, 0);
                context.globalAlpha = 1;
                
                
                //draw text Country
                context.font = 'bold 26px "Open Sans"';
                context.fillStyle = 'black';
                context.textAlign = 'left';
                context.fillText(userProfile.rotaryId, 720, 344);
                context.fillText('30.06.2024', 580, 400);
                //draw text Country
                context.font = 'bold 42px "Georgia"';
                context.fillText(userProfile.nameUkr, 420, 235);
                context.fillText(userProfile.nameEng, 420, 285);

                //
                const overlayedImgDownload = canvas.toDataURL('image/jpeg');
                setDownloadUrl(overlayedImgDownload);
                //after additional drawing
                const overlayedImg = canvas.toDataURL('image/jpeg');
                setOverlayedImage(overlayedImg);
            };
            };
    //}
};

export const overlayImages = (jpgImage, pngImage, selectedSize, scale, offsetX, offsetY, 
            country, district, bgcolor, selectedOptionCustomMode, setOverlayedImage, setDownloadUrl, needCircle) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const jpgImg = new Image();
    jpgImg.src = jpgImage;
    jpgImg.onload = () => {
      let maxWidth, maxHeight;

      if (selectedSize === 'large') {
        maxWidth = 1500;
        maxHeight = 1500;
      } else if (selectedSize === 'medium') {
        maxWidth = 600;
        maxHeight = 600;
      } else if (selectedSize === 'small') {
        maxWidth = 200;
        maxHeight = 200;
      } else if (selectedSize === 'postcard') {
        maxWidth = 1480;
        maxHeight = 1080;
      }

      const imageRatio = jpgImg.width / jpgImg.height;
      let targetWidth = maxWidth;
      let targetHeight = maxHeight;

      if (imageRatio > maxWidth / maxHeight) {
        targetHeight = maxWidth / imageRatio;
      } else {
        targetWidth = maxHeight * imageRatio;
      }

      canvas.width = maxWidth;
      canvas.height = maxHeight;

      // Set green background if necessary
      //if (targetWidth !== maxWidth || targetHeight !== maxHeight) {
        context.fillStyle = bgcolor;
        context.fillRect(0, 0, maxWidth, maxHeight);
      //}
      console.log('fillStyle = '+bgcolor);

      // Scale image
      const scaledWidth = targetWidth * (1 + scale / 10);
        const scaledHeight = targetHeight * (1 + scale / 10);
        const offsetXWithScale = (maxWidth - scaledWidth) / 2 + offsetX;
        const offsetYWithScale = (maxHeight - scaledHeight) / 2 + offsetY;
        context.drawImage(jpgImg, 0, 0, jpgImg.width, jpgImg.height, offsetXWithScale, offsetYWithScale, scaledWidth, scaledHeight);

      // Offset image
      const pngImg = new Image();
      pngImg.crossOrigin = "Anonymous";
      pngImg.src = selectedOptionCustomMode === "option_custom" ? KPI_OVERLAY_CUSTOM :
                    (pngImage);
      pngImg.onload = () => {
        context.drawImage(pngImg, 0, 0);

        if (selectedOptionCustomMode === "option_custom" ) {
          //draw text Country
          context.font = '36px "Open Sans"';
          context.fillStyle = '#FFFFFF';
          context.textAlign = 'right';
          context.fillText(country, 506, 1200-13);
          context.fillText(district, 506-3, 1300-3);
        }
        
        const overlayedImgDownload = canvas.toDataURL('image/jpeg');
        setDownloadUrl(overlayedImgDownload);
        
        if (needCircle) { 
            // Намалювати коло
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const radius = Math.min(canvas.width, canvas.height) / 2 - 8;
            context.beginPath();
            context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
            // Встановити стиль лінії
            context.strokeStyle = 'black'; // Чорний колір
            context.lineWidth = 2; // Товщина лінії
            // Встановити стиль пунктирної лінії
            context.setLineDash([15, 15]); // Масив довжини смуг і пропусків
            context.lineDashOffset = 0; // Початковий зсув
            // Застосувати стиль лінії і намалювати коло
            context.stroke();
        }

        const overlayedImg = canvas.toDataURL('image/jpeg');
        setOverlayedImage(overlayedImg);


        
      };

    };
  };

  