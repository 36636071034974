import React from 'react';

import Main from '../components/Main';

const HomePage = () => {

    return (
        <>
        <Main>
        HomePage
        </Main>
        </>
    )
}

export default HomePage;