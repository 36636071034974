import React, { useEffect, useState, useRef } from 'react';
import { Box, Paper, Button } from '@mui/material';
import CertificateMaker from '../modules/CertificateMaker/CertificateMaker';
import AdminCertificatePanel from '../modules/CertificateMaker/AdminCertificatePanel';
import data from '../modules/CertificateMaker/data/sampleData';
import html2canvas from 'html2canvas';

const PREFIX = "BrendCenter_CertificateMaker_";

const getInitialZoom = () => {
  const savedZoom = localStorage.getItem(`${PREFIX}zoom`);
  return savedZoom ? Number(savedZoom) : 100;
};

const getInitialTranslate = () => {
  const savedTranslate = localStorage.getItem(`${PREFIX}translate`);
  return savedTranslate ? JSON.parse(savedTranslate) : { x: 0, y: 0 };
};

const CertificateMakerPage = () => {
  const [certificateData, setCertificateData] = useState(data);
  const [zoom, setZoom] = useState(getInitialZoom());
  const [dragging, setDragging] = useState(false);
  const [translate, setTranslate] = useState(getInitialTranslate());
  const dragStartRef = useRef({ x: 0, y: 0 });
  const animationFrameId = useRef(null);
  const makerRef = useRef(null);

  useEffect(() => {
    localStorage.setItem(`${PREFIX}zoom`, zoom);
    localStorage.setItem(`${PREFIX}translate`, JSON.stringify(translate));
  }, [zoom, translate]);

  const handleElementChange = (updatedData) => {
    setCertificateData(updatedData);
  };

  const handleZoomChange = (e) => {
    const value = Math.min(Math.max(parseInt(e.target.value, 10), 10), 500); // обмеження масштабу від 10% до 500%
    setZoom(value);
  };

  const handleWheel = (e) => {
    if (makerRef.current && makerRef.current.contains(e.target)) {
      if (e.deltaY < 0) {
        setZoom((prevZoom) => Math.min(prevZoom + 10, 500)); // збільшення масштабу
      } else {
        setZoom((prevZoom) => Math.max(prevZoom - 10, 10)); // зменшення масштабу
      }
    }
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    dragStartRef.current = { x: e.clientX - translate.x, y: e.clientY - translate.y };
  };

  const handleMouseMove = (e) => {
    if (dragging && !animationFrameId.current) {
      animationFrameId.current = requestAnimationFrame(() => {
        setTranslate({ x: e.clientX - dragStartRef.current.x, y: e.clientY - dragStartRef.current.y });
        animationFrameId.current = null;
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
      animationFrameId.current = null;
    }
  };

  const handleDownload = async () => {
    if (makerRef.current) {
      const canvas = await html2canvas(makerRef.current, {
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
        logging: true,
      });
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'certificate.png';
      link.href = dataUrl;
      link.click();
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f0f0f0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px',
        position: 'relative',
        overflow: 'hidden',
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <Paper
        ref={makerRef}
        elevation={3}
        sx={{
          padding: '20px',
          width: 'fit-content',
          transform: `scale(${zoom / 100}) translate(${translate.x / (zoom / 100)}px, ${translate.y / (zoom / 100)}px)`,
          transformOrigin: 'center',
          transition: 'transform 0.2s',
          cursor: dragging ? 'grabbing' : 'grab',
        }}
        onMouseDown={handleMouseDown}
        onWheel={handleWheel}
      >
        <CertificateMaker data={certificateData} />
      </Paper>
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          width: 'fit-content',
          position: 'absolute',
          right: '20px',
          top: '20px',
          maxHeight: 'calc(100vh - 40px)',
          overflowY: 'auto',
        }}
        className="admin-panel"
      >
        <AdminCertificatePanel
          data={certificateData}
          onElementChange={handleElementChange}
          zoom={zoom}
          onZoomChange={handleZoomChange}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownload}
          sx={{ marginTop: '10px' }}
        >
          Download
        </Button>
      </Paper>
    </Box>
  );
};

export default CertificateMakerPage;
